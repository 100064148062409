import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AbstractNavigationItemsService, AppCoreModule } from '@discoverer/app-core';
import { NavigationItemsService } from './shared/navigation-items-service';
import { GraphQlOptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { GraphQueryOptionService } from './ignite/services/graph-query-option-service';
import { GraphQLModule } from './graphql.module';
import { environment } from '@env/environment';




@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GraphQLModule.forRoot(environment.VOCHAP.GraphQlURL, ['vochap']),
        AppCoreModule.forRoot({
            provide: AbstractNavigationItemsService,
            useClass: NavigationItemsService
        })

    ],
    providers: [
        { provide: GraphQlOptionDataServiceInterface, useClass: GraphQueryOptionService },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
