import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingComponent, LayoutComponent } from '@discoverer/app-core';
import { AppGuardService } from './app.guard.service';
import { InitialDataResolver } from './app.resolvers';
import { LayoutPageConfig } from '@discoverer/app-core/config/layout.page.config';
import { UserPageConfig } from '@discoverer/app-core/config/user-page.config';

var layoutPageConfig: LayoutPageConfig =
{
    showLogoImage: false,
    showFullScreen: false,
    showSearch: false,
    userPageConfig: {
        showProfile: false,
        showChangePassword: true,
        showSetting: false,
        showStatus: false,
    } as UserPageConfig
};
const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    {
        path: '',
        canActivate: [AppGuardService],
        component: LayoutComponent,
        data: {
            layout: 'modern',
            layoutConfig: layoutPageConfig
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'vochap', loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule), canActivate: [AppGuardService] },
            { path: 'vochap', outlet: 'dialog', loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule), canActivate: [AppGuardService] },

        ]
    }

];

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService]
})
export class AppRoutingModule {
}
