import {Apollo, } from 'apollo-angular';
import gql from 'graphql-tag';
import { GraphQlOptionDataServiceInterface } from '@discoverer/dynamic-forms';
import { Inject, forwardRef, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { map } from 'rxjs/operators';


@Injectable()
export class GraphQueryOptionService implements GraphQlOptionDataServiceInterface {
    constructor(@Inject(forwardRef(() => Apollo)) private apollo: Apollo) {

    }
    getByOptions(query: string, filterBy?: { [field: string]: { gqlType: string, value?: any, useFieldName?: boolean, alias: string } }): Observable<any[]> {
       try {
            const queryObservable = this.apollo.query({
                query: gql`${query} `,
                variables: filterBy ?
                    Object.values(filterBy).some(x => x.value != undefined && x.value != null) ?
                        Object.assign({}, ...Object.entries(filterBy).filter(x => x[1] && x[1].value !== null && x[1].value !== undefined).map(([field, obj]) => ({
                            [obj.useFieldName ? (obj.alias ? obj.alias : field)
                                : 'filterBy']: obj.value
                        }))) : {}
                    : {}
            });
            return queryObservable.pipe(map((x: any) =>
                x.data[Object.keys(x.data)[0]]
            ));
        } catch (ex) {
            return of();
        }
    }
}
